import { createReducer } from "@reduxjs/toolkit";
import {
  toggleConfirmationDialog,
  forceConfirmUserEmail,
} from "../actions/user_confirmations";
import { UserProps } from "../../dto/user";

export default createReducer<{
  readonly user: UserProps;
  readonly open: boolean;
}>({ user: null, open: false }, (builder) => {
  builder.addCase(toggleConfirmationDialog, (state, action) => ({
    ...action.payload,
  }));
  builder.addCase(forceConfirmUserEmail.pending, (state) => ({
    user: state.user,
    open: false,
  }));
});
