import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { UserProps } from "../../../dto/user";
import { successToaster } from "../../../common/alerts";
import { CancelToken } from "axios";

export const toggleConfirmationDialog = createAction<{
  readonly user: UserProps;
  readonly open: boolean;
}>("user_deletions/open_dialog");

export const updateUserRequest = createAsyncThunk(
  "users/update/role",
  async (user: UserProps) => {
    try {
      const { user: updatedUser } = await api.admin.users.update(user);
      return updatedUser;
    } catch (e) {
      handleErrors("Не удалось сохранить пользователя", e);
    }
  }
);

export const removeUserRequest = createAsyncThunk(
  "users/remove",
  async (user: UserProps) => {
    try {
      await api.admin.users.destroy(user.id);
      successToaster(`Пользователь ${user.email} удалён`);
      return user;
    } catch (e) {
      handleErrors(`Не удалось удалить пользователя ${user.email}`, e);
    }
  }
);

export const loadUsers = createAsyncThunk<UserProps[], CancelToken>(
  "users/load",
  async (cancelToken) => {
    try {
      const { users } = await api.admin.users.list(cancelToken);
      return users;
    } catch (e) {
      handleErrors("Не удалось загрузить пользователей", e);
    }
  }
);
