import React, { FC, useState } from "react";

import { alarmMessages, alarm_messages } from "../helpers/device_state_helper";
import { Popover } from "@mui/material";
import Typography from "@mui/material/Typography";

interface AlarmRowProps {
  alarms: {
    alarmed: boolean;
    input_fan: boolean;
    output_fan: boolean;
    tan_overheating: boolean;
    recuperator_error: boolean;
    fire: boolean;
    sensor_disconnected: boolean;
    output_channel_temp_sensor: boolean;
    frost_defence: boolean;
    channel_frozen: boolean;
    winter_mode_switch_error: boolean;
    recuperator_rotation_error: boolean;
    no_coolant_error: boolean;
    backwater_temp_sensor: boolean;
    not_enough_eeprom: boolean;
    humidity_sensor_error: boolean;
    co2_sensor_error: boolean;
  };
}

const AlarmRow: FC<AlarmRowProps> = ({ alarms }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      <div
        className="device-alarm text-left"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ display: alarms.alarmed ? "" : "none" }}
      >
        <label>{alarmMessages(alarms)}</label>
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {Object.keys(alarms).map((alarm_name) => {
          if (alarm_name === "alarmed" || !alarms[alarm_name]) return null;
          return (
            <Typography key={alarm_name} sx={{ p: 1 }}>
              {alarm_messages[alarm_name]}
            </Typography>
          );
        })}
      </Popover>
    </React.Fragment>
  );
};

export default AlarmRow;
