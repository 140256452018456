import { apiRequest } from "./common";
import { ProfileUserProps } from "../dto/user";

const API_URL = "/api/v1/profile";

const show = async () => apiRequest<{ user: ProfileUserProps }>(API_URL);

const update = async (data: any) =>
  apiRequest<{ user: ProfileUserProps }>(API_URL, "put", data);

const updatePassword = async (
  userId: number,
  old_password: string,
  password: string,
  password_confirmation: string
) =>
  apiRequest<{ user: ProfileUserProps }>(
    `/api/v1/profile/password/${userId}`,
    "put",
    {
      old_password,
      password,
      password_confirmation,
    }
  );

const newPassword = async (email: string) =>
  apiRequest<{ success: boolean; message: string }>(
    "/api/v1/auth/password",
    "post",
    { email }
  );

const resetPassword = async (
  password: string,
  password_confirmation: string,
  reset_password_token: string
) =>
  apiRequest<{ success: boolean; message: string }>(
    "/api/v1/auth/password",
    "put",
    { password, password_confirmation, reset_password_token }
  );

export const profile = {
  show,
  update,
  updatePassword,
  newPassword,
  resetPassword,
};
