import { createAction } from "@reduxjs/toolkit";

export const setSettings = createAction<any[]>(
  "setting-registers-dialog/set-settings"
);
export const setLabel = createAction<string>(
  "setting-registers-dialog/set-label"
);
export const setShowToUser = createAction<boolean>(
  "setting-registers-dialog/set-show-to-user"
);
