import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { UserProps } from "../../../dto/user";
import { successToaster } from "../../../common/alerts";

export const toggleConfirmationDialog = createAction<{
  readonly user: UserProps;
  readonly open: boolean;
}>("user_confirmations/open_dialog");

export const forceConfirmUserEmail = createAsyncThunk(
  "user_confirmations/resend",
  async (user: UserProps) => {
    try {
      const { user: updatedUser } = await api.admin.userConfirmations.update(
        user
      );
      successToaster(`Учётная запись <${user.email}> подтверждена`);
      return updatedUser;
    } catch (e) {
      handleErrors(`Не удалось подтвердить учётную запись <${user.email}>`, e);
    }
  }
);
