import axios, { AxiosRequestConfig } from "axios";

export const apiRequest = async <T>(
  url: string,
  method = "GET",
  payload = {},
  headers = {}
  // cancelToken?: CancelToken
): Promise<T> => {
  const config: AxiosRequestConfig = {
    url,
    method,
    headers,
  };
  if (method === "GET" || method === "get") {
    config.params = payload;
  } else {
    config.data = payload;
  }
  try {
    const { data } = await axios(config);
    return data as T;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("Request canceled", e.message);
    } else {
      throw e;
    }
  }
};
