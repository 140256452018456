import { Store } from "redux";
import { BusstationState, DispatchType } from "./type";

import { applyMiddleware, compose, createStore } from "redux";
import reducer from "../reducers";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

const loggerMiddleware = createLogger();

const storeEnhancer = compose(
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

const finalCreateStore = storeEnhancer(createStore);

export type StoreType = Store<BusstationState> & {
  dispatch: DispatchType;
};

function configureStore(
  initialState: BusstationState = {
    firmwares: [],
    firmware: { registers: [] },
    interfaces: [],
    devices: [],
    device_interface: { rows: [] },
    current_firmware: {},
    block: {},
    blocks: [],
    toolbar_blocks: [],
    current_device_map: {
      device_interface: { rows: [] },
      device_map: {
        registers: [],
        control_registers: {},
        register_settings: [],
      },
      current_block: null,
      firmwares: [],
    },
    device_maps: [],
    panel: {
      id: 0,
      serial: "",
      label: "",
      template: {
        settings: [],
        admin_settings: [],
        firmware: {},
        rows: [],
      },
      admin_settings: [],
      token: "",
      action_in_progress: false,
      payload: [],
      schedule: [],
      settings: [],
      time: [],
      schedule_mode: false,
      created_at: null,
      firmware: { version: null },
      interface: { version: null },
      isoTimeSetAt: null,
    },
    edit_dialog_visible: false,
    read_address: 0,
    write_address: 0,
    users: [],
    device_bindings: [],
    bindings_user: {},
    profile: {
      id: 0,
      avatar_url: "",
      mock_avatar: { name: "", size: 0, type: "" },
      notify_via_email: false,
      notify_via_sms: false,
      email: "",
    },
    reduxTokenAuth: {
      currentUser: {
        isLoading: false,
        isSignedIn: false,
        attributes: {
          id: null,
          email: null,
          firstName: null,
          lastName: null,
          role: null,
          notifyViaSms: false,
          notifyViaEmail: false,
          phone: null,
          avatarUrl: null,
          mockAvatar: {},
        },
      },
    },
    isLoading: false,
    isMenuOpen: false,
    userDeletion: {
      user: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        role: null,
        name: null,
        created_at: null,
        confirmed_at: null,
        active: false,
        avatar: null,
      },
      open: false,
    },
    userConfirmations: {
      user: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        role: null,
        name: null,
        created_at: null,
        confirmed_at: null,
        active: false,
        avatar: null,
      },
      open: false,
    },
    confirmationEmails: {
      user: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        role: null,
        name: null,
        created_at: null,
        confirmed_at: null,
        active: false,
        avatar: null,
      },
      open: false,
    },
    registersDialog: {
      label: "",
      showToUser: false,
      settings: [],
    },
  }
): StoreType {
  return finalCreateStore(reducer, initialState);
}

const store: StoreType = configureStore();

export default store;
