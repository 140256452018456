import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import React, { FC, useCallback } from "react";
import DefaultTransition from "./DefaultTransition";

export interface ConfirmationDialogProps {
  readonly open: boolean;
  readonly onOk: () => void;
  readonly onClose: () => void;
  readonly title: string;
  readonly content: string;
  readonly cancelButtonText: string;
  readonly okButtonText: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  content,
  cancelButtonText,
  okButtonText,
  onClose,
  onOk,
}) => {
  const handleOk = useCallback(
    (e) => {
      e.preventDefault();
      onOk();
      onClose();
    },
    [onOk, onClose]
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={DefaultTransition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelButtonText}</Button>
        <Button onClick={handleOk} autoFocus>
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
