import React, { FC, useCallback } from "react";
import moment from "moment/moment";
import { DATE_FORMAT } from "../../common";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import {
  toggleConfirmationDialog as toggleUserDeletionConfirmation,
  updateUserRequest,
} from "../../redux/actions/users/users";
import { Link } from "react-router-dom";
import { UserProps } from "../../dto/user";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { toggleConfirmationDialog as toggleConfirmationInstructionsSending } from "../../redux/actions/confirmation_emails";
import { toggleConfirmationDialog as toggleUserAccountConfirmation } from "../../redux/actions/user_confirmations";
import { successToaster } from "../../common/alerts";

const UserRow: FC<{
  user: UserProps;
  disabled: boolean;
}> = ({ user, disabled }) => {
  const dispatch = useDispatch<DispatchType>();

  const handleUserDeletionOpen = useCallback(
    () => dispatch(toggleUserDeletionConfirmation({ user, open: true })),
    [dispatch, user]
  );

  const handleConfirmationInstructionsOpen = useCallback(
    () => dispatch(toggleConfirmationInstructionsSending({ user, open: true })),
    [dispatch, user]
  );

  const handleConfirmUserEmailOpen = useCallback(
    () => dispatch(toggleUserAccountConfirmation({ user, open: true })),
    [dispatch, user]
  );

  const toggleUserActive = useCallback(() => {
    const active = !user.active;
    dispatch(updateUserRequest({ ...user, active })).then(
      successToaster(
        `Аккаунт пользователя ${user.email} ${
          active ? "активирован" : "отключен"
        }`
      )
    );
  }, [dispatch, user]);

  const changeUserRole = useCallback(
    (e) => {
      dispatch(updateUserRequest({ ...user, role: e.target.value })).then(
        successToaster(`Роль пользователя ${user.email} изменена`)
      );
    },
    [dispatch, user]
  );

  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <Link
            to={`/admin/users/${user.id}/panels`}
            title="Управление устройствами пользователя"
          >
            {user.email}
          </Link>
        </TableCell>
        <TableCell>{moment(user.created_at).format(DATE_FORMAT)}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>
          <FormControl fullWidth>
            <Select
              onChange={changeUserRole}
              value={user.role}
              disabled={disabled}
            >
              <MenuItem value="engineer">Пользователь</MenuItem>
              <MenuItem value="reseller">Дилер</MenuItem>
              <MenuItem value="admin">Администратор</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <Checkbox checked={user.active} onClick={toggleUserActive} />
          <label htmlFor={`user_active${user.id}`} />
          <Tooltip title="Выслать письмо для подтверждения почты">
            <IconButton
              color="primary"
              onClick={handleConfirmationInstructionsOpen}
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Подтвердить почту пользователя принудительно">
            <IconButton color="primary" onClick={handleConfirmUserEmailOpen}>
              <MarkEmailReadIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title="Удалить пользователя">
            <IconButton color="primary" onClick={handleUserDeletionOpen}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default UserRow;
