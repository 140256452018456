import React, { useEffect } from "react";
import UsersTable from "./UsersTable";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadUsers } from "../../redux/actions/users/users";
import { LoadingProgress } from "../common/LoadingProgress";
import axios from "axios";

export default function UsersPanel() {
  const dispatch = useDispatch<DispatchType>();
  const users = useSelector<BusstationState, any[]>((state) => state.users);

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const isLoading = useSelector<BusstationState, boolean>(
    (state) => state.isLoading
  );

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!currentUser.isLoading) {
      dispatch(loadUsers(source.token));
    }
    return () => {
      source.cancel("Operation canceled by unload handler.");
    };
  }, [dispatch, currentUser.isLoading]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Пользователи</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              {currentUser.isLoading || isLoading ? (
                <LoadingProgress />
              ) : (
                <UsersTable users={users} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
