import React, { FC, useCallback } from "react";
import {
  initEditRegisterDialog,
  toggleEditDialog,
} from "../../redux/actions/device_map/device_map";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import range from "lodash/range";
import {
  setLabel,
  setSettings,
  setShowToUser,
} from "../../redux/actions/setting_registers_dialog";

const EditButton: FC<{
  read_address: number;
  write_address: number;
  onClick: (readAddr: number, writeAddr: number) => void;
}> = ({ read_address, write_address, onClick }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick(read_address, write_address);
    },
    [read_address, write_address, onClick]
  );

  return (
    <button
      title="Редактировать регистр"
      className="btn btn-default"
      onClick={handleClick}
      type="button"
    >
      <i className="material-icons">edit</i>
    </button>
  );
};

const AdminSettingsTable: FC<{
  device_map: any;
  firmware: any;
  field_name: string;
}> = ({ device_map, firmware, field_name }) => {
  const dispatch = useDispatch<DispatchType>();
  const current_device_map = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );
  const showEditDialog = useCallback(
    (read_address, write_address) => {
      dispatch(initEditRegisterDialog({ read_address, write_address }));
      const currentSetting =
        current_device_map.device_map.register_settings.find(
          (rs) =>
            rs.read_address === read_address &&
            rs.write_address === write_address
        );
      const { label, show_to_user, settings } = currentSetting || {
        label: "",
        show_to_user: false,
        settings: [],
      };
      dispatch(setLabel(label));
      dispatch(setShowToUser(show_to_user));
      if (settings) dispatch(setSettings(settings));
      dispatch(toggleEditDialog(true));
    },
    [dispatch, current_device_map]
  );
  const write_end_reg = device_map[`${field_name}_write_end_reg`];
  const write_start_reg = device_map[`${field_name}_write_start_reg`];
  let write_len = 0;
  if (!(write_end_reg === void 0 || write_start_reg === void 0))
    write_len = write_end_reg - write_start_reg;
  const read_end_reg = device_map[`${field_name}_read_end_reg`];
  const read_start_reg = device_map[`${field_name}_read_start_reg`];
  let read_len = 0;
  if (!(read_end_reg === void 0 || read_start_reg === void 0))
    read_len = read_end_reg - read_start_reg;
  const regs_len = Math.max(write_len, read_len);
  if (
    !(
      device_map[`${field_name}_read_end_reg`] === void 0 ||
      device_map[`${field_name}_read_start_reg`] === void 0 ||
      device_map[`${field_name}_write_start_reg`] === void 0 ||
      device_map[`${field_name}_write_end_reg`] === void 0
    )
  ) {
    return (
      <>
        <div className="row clearfix">
          <div className="col-lg-9 col-md-9 col-sm-6 col-xs-6">
            <label className="form-label">Описание</label>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
            <label className="form-label">Чтение</label>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
            <label className="form-label">Запись</label>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
            <label />
          </div>
        </div>
        {range(regs_len + 1).map((i) => {
          const read_address = device_map[`${field_name}_read_start_reg`] + i;
          const write_address = device_map[`${field_name}_write_start_reg`] + i;
          const read_descr = firmware.register(read_address).description;
          const write_descr = firmware.register(write_address).description;
          return (
            <div className="row clearfix" key={i}>
              <div className="col-lg-9 col-md-9 col-sm-6 col-xs-6">
                <label className="form-label">{`${read_descr} - ${write_descr}`}</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                <label className="form-label">{read_address}</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                <label className="form-label">{write_address}</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                <EditButton
                  read_address={read_address}
                  write_address={write_address}
                  onClick={showEditDialog}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    return null;
  }
};

export default AdminSettingsTable;
