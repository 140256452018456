import React, { FC, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { Link, useLocation } from "react-router-dom";
import { signOutUser } from "../../api/redux-token-auth-config";
import { setIsMenuOpen } from "../../redux/actions/menu";
import { useClickOutside } from "../../hooks/useClickOutside";

const LeftSideBar: FC = () => {
  const menuRef = useRef<HTMLElement | null>(null);
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const location = useLocation();
  const dispatch = useDispatch<DispatchType>();

  const handleClickAway = useCallback(
    (e: MouseEvent) => {
      if ((e.target as HTMLElement).className !== "bars") {
        dispatch(setIsMenuOpen(false));
      }
    },
    [dispatch]
  );

  useClickOutside(menuRef, handleClickAway);

  return (
    <aside ref={menuRef} id="leftsidebar" className="sidebar">
      <div className="user-info">
        <div className="image">
          {currentUser.attributes.avatar_url && (
            <img
              style={{ width: "48px", height: "48px" }}
              alt="Avatar"
              src={currentUser.attributes.avatar_url}
            />
          )}
        </div>
        <div className="info-container">
          <div
            className="name"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {`${currentUser.attributes.first_name} ${currentUser.attributes.last_name}`}
          </div>
          <div className="email">{currentUser.attributes.email}</div>
        </div>
      </div>
      <div className="menu">
        <ul className="list">
          <li className="header">Главное меню</li>
          <li
            className={
              location.pathname === "/" ||
              location.pathname.indexOf("/panels") > -1
                ? "active"
                : ""
            }
          >
            <Link to="/">
              <i className="material-icons">cast_connected</i>
              <span>Панели</span>
            </Link>
          </li>
          {currentUser.attributes.role === "admin" && (
            <>
              <li
                className={
                  location.pathname.indexOf("/firmwares") > -1 ? "active" : ""
                }
              >
                <Link to="/firmwares">
                  <i className="material-icons">memory</i>
                  <span>Прошивки</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.indexOf("/interfaces") > -1 ? "active" : ""
                }
              >
                <Link to="/interfaces">
                  <i className="material-icons">developer_board</i>
                  <span>Интерфейсы</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.indexOf("/device-maps") > -1 ? "active" : ""
                }
              >
                <Link to="/device-maps">
                  <i className="material-icons">device_hub</i>
                  <span>Привязки интерфейсов</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.indexOf("/interface-blocks") > -1
                    ? "active"
                    : ""
                }
              >
                <Link to="/interface-blocks">
                  <i className="material-icons">build</i>
                  <span>Блоки интерфейса</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.indexOf("/users") > -1 ? "active" : ""
                }
              >
                <Link to="/admin/users">
                  <i className="material-icons">people</i>
                  <span>Пользователи</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname.indexOf("/profile") > -1 ? "active" : ""
                }
              >
                <Link to="/profile">
                  <i className="material-icons">person</i>
                  <span>Профиль</span>
                </Link>
              </li>
            </>
          )}
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch(signOutUser());
              }}
            >
              <i className="material-icons">input</i>
              <span>Выход</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="legal">
        <div className="copyright">
          &copy; 2018 <a href="#">Вентиляционный завод Интех</a>.
        </div>
        <div className="version">
          <a href="tel:8-800-500-45-15">
            <i
              className="material-icons md-18"
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
                lineHeight: "18px",
              }}
            >
              phone
            </i>
            <span
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
                lineHeight: "18px",
              }}
            >
              8 800 500 45 15 (бесплатно по РФ)
            </span>
          </a>
        </div>
        <div className="version">
          <a href="mailto:intech_07@mail.ru">
            <i
              className="material-icons md-18"
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
                lineHeight: "18px",
              }}
            >
              email
            </i>
            <span
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
                lineHeight: "18px",
              }}
            >
              intech_07@mail.ru
            </span>
          </a>
        </div>
      </div>
    </aside>
  );
};

export default LeftSideBar;
