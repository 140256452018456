import { createReducer } from "@reduxjs/toolkit";
import { SettingsRegistersDialogProps } from "../../components/device_maps/SettingsRegistersDialog";
import {
  setLabel,
  setSettings,
  setShowToUser,
} from "../actions/setting_registers_dialog";

export default createReducer<SettingsRegistersDialogProps>(
  { label: "", showToUser: false, settings: [] },
  (builder) => {
    builder.addCase(setLabel, (state, action) => ({
      ...state,
      label: action.payload,
    }));
    builder.addCase(setShowToUser, (state, action) => ({
      ...state,
      showToUser: action.payload,
    }));
    builder.addCase(setSettings, (state, action) => ({
      ...state,
      settings: action.payload,
    }));
  }
);
