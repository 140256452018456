import React, { FC, useCallback, useEffect, useState } from "react";
import MapTabsContainer from "./MapTabs";
import SettingsRegistersDialog from "./SettingsRegistersDialog";

import {
  loadDeviceMap,
  requestCreateDeviceMap,
  requestUpdateDeviceMap,
} from "../../redux/actions/device_map/device_map";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingProgress } from "../common/LoadingProgress";
import { setFirmware } from "../../redux/actions/firmware";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { FirmwareProps } from "../../dto/device-map";

const DeviceMap: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const firmware = useSelector<BusstationState, any>((state) => state.firmware);

  const { deviceMapId } = useParams();

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const { interfaceId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadDeviceMap({ deviceMapId, interfaceId }));
    }
  }, [dispatch, deviceMapId, interfaceId, currentUser.isLoading]);

  const current_device_map = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );

  const handleFirmwareChanged = useCallback(
    (_e: any, firmware: FirmwareProps | null) => {
      if (firmware) {
        dispatch(setFirmware(firmware));
      }
    },
    [dispatch]
  );

  const handleSave = useCallback(async () => {
    if (
      current_device_map.device_map.id === void 0 ||
      current_device_map.device_map.id === null
    ) {
      const device_map = await dispatch(
        requestCreateDeviceMap(current_device_map.device_map)
      ).unwrap();
      navigate(`/device-maps/${device_map.id}`);
    } else {
      dispatch(requestUpdateDeviceMap(current_device_map.device_map));
    }
  }, [current_device_map.device_map, dispatch, navigate]);

  if (
    !current_device_map?.device_map?.id &&
    !current_device_map?.device_interface?.id
  )
    return <LoadingProgress />;

  const loading =
    open &&
    (!current_device_map.firmwares ||
      current_device_map.firmwares.length === 0);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <h2>Привязка интерфейса</h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-5 form-control-label">
                    <label htmlFor="version">Прошивка</label>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-4 col-xs-7">
                    <div className="form-group">
                      <div className="form-line">
                        <Autocomplete
                          id="firmware-search-form"
                          autoHighlight
                          options={current_device_map?.firmwares || []}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          isOptionEqualToValue={(
                            option: FirmwareProps,
                            value: FirmwareProps
                          ) => option.id === value.id}
                          getOptionLabel={(option: FirmwareProps) =>
                            option.version?.toString() || ""
                          }
                          loading={loading}
                          value={firmware.id ? firmware : null}
                          onChange={handleFirmwareChanged}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Поиск прошивки"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        {/*<Select*/}
                        {/*  className="form-control"*/}
                        {/*  value={firmware.id || ""}*/}
                        {/*  onChange={handleFirmwareChanged}*/}
                        {/*>*/}
                        {/*  {current_device_map.firmwares.map((fw) => (*/}
                        {/*    <MenuItem key={fw.id} value={fw.id}>*/}
                        {/*      {fw.version}*/}
                        {/*    </MenuItem>*/}
                        {/*  ))}*/}
                        {/*</Select>*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg waves-effect"
                        onClick={handleSave}
                      >
                        Сохранить привязку
                      </button>
                    </div>
                  </div>
                </div>
                <MapTabsContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettingsRegistersDialog />
    </>
  );
};

export default DeviceMap;
