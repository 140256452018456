import LeftSideBar from "../left_side_bar/LeftSideBar";
import UserEventFeed from "../panels/UserEventFeed";
import { Outlet } from "react-router-dom";
import React, { useCallback } from "react";
import Logo from "images/logo-bg-w-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { setIsMenuOpen } from "../../redux/actions/menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfirmUserDeletionDialog from "../users/dialogs/ConfirmUserDeletionDialog";
import ConfirmResendConfirmationInstructionsDialog from "../users/dialogs/ConfirmResendConfirmationInstructionsDialog";
import ForceConfirmUserEmailDialog from "../users/dialogs/ForceConfirmUserEmailDialog";

export const App = () => {
  const matches = useMediaQuery("(min-width:1170px)");

  const isMenuOpen = useSelector<BusstationState, boolean>(
    (state) => state.isMenuOpen
  );

  const dispatch = useDispatch<DispatchType>();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(setIsMenuOpen(!isMenuOpen));
    },
    [dispatch, isMenuOpen]
  );

  return (
    <section
      className={`${isMenuOpen ? " overlay-open" : ""}${
        matches ? "" : " ls-closed"
      }`}
    >
      <div className={`overlay${isMenuOpen ? " fade-in" : " fade-out"}`}></div>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-header">
            <a
              onClick={handleClick}
              className="bars"
              style={{
                display: matches ? "none" : "block",
                opacity: matches ? 0 : 1,
                transition: "opacity: 2s",
              }}
            ></a>
            <a className="navbar-brand" href="//intechvent.ru">
              <img src={Logo} alt="Logo" />
              <span className="hidden-xs">Диспетчеризация</span>
            </a>
          </div>
        </div>
      </nav>
      <section>
        <LeftSideBar />
      </section>
      <section className="content">
        <UserEventFeed />
        <Outlet />
      </section>
      <ConfirmUserDeletionDialog />
      <ConfirmResendConfirmationInstructionsDialog />
      <ForceConfirmUserEmailDialog />
    </section>
  );
};
