import DeviceMapDecorator from "../decorators/device_map_decorator";
import { createReducer } from "@reduxjs/toolkit";
import {
  addBitMask,
  blockMeasUnitsChanged,
  blockRegisterChanged,
  blockSecondMeasUnitsChanged,
  blockValueRegisterChanged,
  controlRegChanged,
  currentBlockChanged,
  loadDeviceMap,
  regChanged,
  regSaveSettings,
  removeBitMask,
  toggleBit,
  toggleValuesNumber,
} from "../actions/device_map/device_map";
import { setFirmware } from "../actions/firmware";
import {
  DeviceInterfaceProps,
  DeviceMapProps,
  FirmwareProps,
  RegisterProps,
} from "../../dto/device-map";

import flatten from "lodash/flatten";

const DefaultDeviceMap: DeviceMapProps = {
  registers: [],
  control_registers: {},
  register_settings: [],
};

export default createReducer<{
  device_map: DeviceMapProps;
  current_block?: any;
  firmwares: FirmwareProps[];
  device_interface: Pick<DeviceInterfaceProps, "rows">;
}>(
  {
    device_map: DefaultDeviceMap,
    firmwares: [],
    device_interface: { rows: [] },
  },
  (builder) => {
    builder.addCase(setFirmware, (state, action) => {
      if (action.payload) {
        const registers = flatten(
          state.device_interface.rows.map((row) =>
            row.blocks.map(
              (block): RegisterProps => ({
                id: block.id,
                register: 0,
                value_register: 0,
                meas_units: 0,
                second_meas_units: 0,
                two_values: false,
              })
            )
          )
        );
        return {
          ...state,
          device_map: {
            ...state.device_map,
            firmware_id: action.payload.id,
            registers,
            settings_read_start_reg: 0,
            settings_read_end_reg: 0,
            settings_write_start_reg: 0,
            settings_write_end_reg: 0,
            admin_settings_read_start_reg: 0,
            admin_settings_read_end_reg: 0,
            admin_settings_write_start_reg: 0,
            admin_settings_write_end_reg: 0,
            schedule_read_start_reg: 0,
            schedule_read_end_reg: 0,
            schedule_write_start_reg: 0,
            schedule_write_end_reg: 0,
            time_read_start_reg: 0,
            time_read_end_reg: 0,
            time_write_start_reg: 0,
            time_write_end_reg: 0,
            control_registers: { status: 0, state_change: 0, wanted_state: 0 },
          },
        };
      } else {
        return {
          ...state,
          device_map: DefaultDeviceMap,
        };
      }
    });
    builder.addCase(regChanged, (state, action) => ({
      ...state,
      device_map: {
        ...state.device_map,
        [action.payload.name]: action.payload.value,
      },
    }));
    builder.addCase(regSaveSettings, (state, action) => {
      return saveRegisterSettings(state, action.payload);
    });
    builder.addCase(controlRegChanged, (state, action) => {
      return {
        ...state,
        device_map: {
          ...state.device_map,
          control_registers: {
            ...state.device_map.control_registers,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    });
    builder.addCase(loadDeviceMap.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(currentBlockChanged, (state, action) => {
      const block_idx = state.device_map.registers.findIndex(
        (r) => r.id === action.payload.id
      );
      const block =
        block_idx < 0
          ? action.payload
          : { ...action.payload, ...state.device_map.registers[block_idx] };
      return { ...state, current_block: block };
    });
    builder.addCase(addBitMask, (state) => {
      return blockPropertyChanged(state, {
        bit_mask: [
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0],
        ],
      });
    });
    builder.addCase(removeBitMask, (state) => {
      return blockPropertyChanged(state, { bit_mask: null });
    });
    builder.addCase(toggleBit, (state, action) => {
      const current_block_idx = state.device_map.registers.findIndex(
        (r) => r.id === state.current_block.id
      );
      let bit_mask = [
        state.device_map.registers[current_block_idx].bit_mask[0].slice(),
        state.device_map.registers[current_block_idx].bit_mask[1].slice(),
      ];
      bit_mask[action.payload.row_index][action.payload.cell_index] ^= 1;
      const idx = state.device_map.registers.findIndex(
        (r) => r.id === state.current_block.id
      );
      const new_register = { id: state.current_block.id, bit_mask };

      const new_map = {
        ...state.device_map,
        registers:
          idx < 0
            ? [...state.device_map.registers, new_register]
            : [
                ...state.device_map.registers.slice(0, idx),
                Object.assign(
                  {},
                  state.device_map.registers[idx],
                  new_register
                ),
                ...state.device_map.registers.slice(idx + 1),
              ],
      };

      bit_mask = [
        state.current_block.bit_mask[0].slice(),
        state.current_block.bit_mask[1].slice(),
      ];
      bit_mask[action.payload.row_index][action.payload.cell_index] ^= 1;
      return {
        ...state,
        current_block: Object.assign({}, state.current_block, {
          bit_mask: bit_mask,
        }),
        device_map: new_map,
      };
    });
    builder.addCase(blockRegisterChanged, (state, action) =>
      blockPropertyChanged(state, { register: action.payload })
    );
    builder.addCase(blockValueRegisterChanged, (state, action) =>
      blockPropertyChanged(state, { value_register: action.payload })
    );
    builder.addCase(blockMeasUnitsChanged, (state, action) =>
      blockPropertyChanged(state, { meas_units: action.payload })
    );
    builder.addCase(blockSecondMeasUnitsChanged, (state, action) =>
      blockPropertyChanged(state, { second_meas_units: action.payload })
    );
    builder.addCase(toggleValuesNumber, (state, action) =>
      blockPropertyChanged(state, { two_values: action.payload })
    );
  }
);

function saveRegisterSettings(state, settings) {
  const device_map = state.device_map ?? { register_settings: [] };
  const device_map_decorator = new DeviceMapDecorator(device_map);
  const setting = device_map_decorator.register_settings(
    settings.read_address,
    settings.write_address
  ) ?? {
    label: "",
    show_to_user: false,
  };

  const new_setting = { ...setting, ...settings };
  const setting_idx = device_map_decorator.register_settings_index(
    settings.read_address,
    settings.write_address
  );
  return {
    ...state,
    device_map: {
      ...device_map,
      register_settings: updateSettingsArray(
        device_map,
        setting_idx,
        new_setting
      ),
    },
  };
}

function updateSettingsArray(device_map, setting_idx, new_setting) {
  return setting_idx < 0
    ? [...device_map.register_settings.slice(), new_setting]
    : [
        ...device_map.register_settings.slice(0, setting_idx),
        new_setting,
        ...device_map.register_settings.slice(setting_idx + 1),
      ];
}

function blockPropertyChanged(state: any, payload: any) {
  const idx = state.device_map.registers.findIndex(
    (r) => r.id === state.current_block.id
  );
  const new_register: any = {
    id: state.current_block.id,
    ...payload,
  };
  if (!payload.two_values) new_register.second_meas_units = null;
  const new_map = {
    ...state.device_map,
    registers:
      idx < 0
        ? [...state.device_map.registers, new_register]
        : [
            ...state.device_map.registers.slice(0, idx),
            { ...state.device_map.registers[idx], ...new_register },
            ...state.device_map.registers.slice(idx + 1),
          ],
  };
  const new_current_block = { ...payload };
  return {
    ...state,
    current_block: { ...state.current_block, ...new_current_block },
    device_map: new_map,
  };
}
