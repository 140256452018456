import React, { FC, useCallback } from "react";
import {
  removeUserRequest,
  toggleConfirmationDialog,
} from "../../../redux/actions/users/users";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../../redux/store/type";
import ConfirmationDialog from "./ConfirmationDialog";
import { UserActionConfirmationProps } from "../../../dto/user-action-confirmation-props";

const ConfirmUserDeletionDialog: FC = () => {
  const { user, open } = useSelector<
    BusstationState,
    UserActionConfirmationProps
  >((state) => state.userDeletion);

  const dispatch = useDispatch<DispatchType>();

  const handleOk = useCallback(() => {
    dispatch(removeUserRequest(user));
  }, [dispatch, user]);

  const onClose = useCallback(() => {
    dispatch(toggleConfirmationDialog({ user, open: false }));
  }, [dispatch, user]);

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onOk={handleOk}
      title="Удалить пользователя?"
      content={`Вы уверены, что хотите удалить пользователя <${user.email}>? Действие нельзя будет отменить`}
      cancelButtonText="Не удалять"
      okButtonText="Удалить"
    />
  );
};

export default ConfirmUserDeletionDialog;
