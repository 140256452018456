import { apiRequest } from "../common";
import { UserProps } from "../../dto/user";

const API_URL = "/api/v1/admin/users";
const userUrl = (userId: number | string) => `${API_URL}/${userId}`;

const list = async (cancelToken) =>
  apiRequest<{ readonly users: UserProps[] }>(
    API_URL,
    "GET",
    {},
    {}
    // cancelToken
  );

const update = async (user: any) =>
  apiRequest<{ user: UserProps }>(userUrl(user.id), "put", user);

const destroy = async (userId: number | string) =>
  apiRequest<{ user: UserProps }>(userUrl(userId), "delete");

export const users = {
  list,
  update,
  destroy,
};
