import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../../redux/store/type";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  toggleConfirmationDialog,
  forceConfirmUserEmail,
} from "../../../redux/actions/user_confirmations";
import { UserActionConfirmationProps } from "../../../dto/user-action-confirmation-props";

const ForceConfirmUserEmailDialog: FC = () => {
  const { user, open } = useSelector<
    BusstationState,
    UserActionConfirmationProps
  >((state) => state.userConfirmations);

  const dispatch = useDispatch<DispatchType>();

  const handleOk = useCallback(() => {
    dispatch(forceConfirmUserEmail(user));
  }, [dispatch, user]);

  const onClose = useCallback(() => {
    dispatch(toggleConfirmationDialog({ user, open: false }));
  }, [dispatch, user]);

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onOk={handleOk}
      title="Подтвердить учётную запись?"
      content={`Подтвердить учётную запись <${user ? user.email : ""}>?`}
      cancelButtonText="Не подтверждать"
      okButtonText="Подтвердить"
    />
  );
};

export default ForceConfirmUserEmailDialog;
