import { apiRequest } from "../common";

const API_URL = "/api/v1/admin/user_confirmations";

const userUrl = (userId: number | string) => `${API_URL}/${userId}`;

const update = async (user: any) =>
  apiRequest<{ user: { id: number } }>(userUrl(user.id), "put", {});

export const userConfirmations = {
  update,
};
