import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../../redux/store/type";
import {
  toggleConfirmationDialog,
  resendConfirmationInstructions,
} from "../../../redux/actions/confirmation_emails";
import ConfirmationDialog from "./ConfirmationDialog";
import { UserActionConfirmationProps } from "../../../dto/user-action-confirmation-props";

const ConfirmResendConfirmationInstructionsDialog: FC = () => {
  const { user, open } = useSelector<
    BusstationState,
    UserActionConfirmationProps
  >((state) => state.confirmationEmails);

  const dispatch = useDispatch<DispatchType>();

  const handleOk = useCallback(() => {
    dispatch(resendConfirmationInstructions(user));
  }, [dispatch, user]);

  const onClose = useCallback(() => {
    dispatch(toggleConfirmationDialog({ user, open: false }));
  }, [dispatch, user]);

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onOk={handleOk}
      title="Выслать инструкции для подтверждения учётной записи?"
      content={`Выслать инструкции для подтверждения учётной записи на адрес <${user.email}>?`}
      cancelButtonText="Не высылать"
      okButtonText="Выслать"
    />
  );
};

export default ConfirmResendConfirmationInstructionsDialog;
