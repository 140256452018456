import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { UserProps } from "../../../dto/user";
import { successToaster } from "../../../common/alerts";

export const toggleConfirmationDialog = createAction<{
  readonly user: UserProps;
  readonly open: boolean;
}>("confirmation_emails/open_dialog");

export const resendConfirmationInstructions = createAsyncThunk(
  "confirmation_emails/resend",
  async (user: UserProps) => {
    try {
      await api.admin.confirmationEmails.update(user);
      successToaster(
        `Инструкции по подтверждению учётной записи <${user.email}> отправлены.`
      );
      return user;
    } catch (e) {
      handleErrors(
        `Не удалось отправить инструкции по подтверждению учётной записи <${user.email}>`,
        e
      );
    }
  }
);
