import { createReducer } from "@reduxjs/toolkit";
import { isLoading } from "../actions/is-loading";
import { loadPanels } from "../actions/panels/panel";
import { loadUsers } from "../actions/users/users";

export default createReducer<boolean>(false, (builder) => {
  builder.addCase(isLoading, (state, action) => action.payload);
  builder.addCase(loadPanels.pending, () => true);
  builder.addCase(loadPanels.fulfilled, () => false);
  builder.addCase(loadPanels.rejected, () => false);
  builder.addCase(loadUsers.pending, () => true);
  builder.addCase(loadUsers.fulfilled, () => false);
  builder.addCase(loadUsers.rejected, () => false);
});
